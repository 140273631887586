<template>
  <!-- <i slot="reference" :style="isPlay==false?'':'color: red;'" :class="isPlay==false?'el-icon-video-play':'el-icon-video-pause'" @click="autoPlay" style="cursor: pointer;margin-right: 10px;margin-top: 3px;font-size: 25px"></i> -->
  <div class="sound" @click="autoPlay">
    <img v-if="!isPlay" src="https://newoss.zhulong.com/forum/202405/15/11/1715745493590909.png" alt="">
    <img v-else src="https://newoss.zhulong.com/forum/202405/15/12/1715746097385106.png" alt="">
  </div>
</template>

<script>
export default {
  props: {
    recordFile: {
      type: String
    },
    currentAudioIndex:{
      type: Number
    }
  },
  name: 'audioplay',
  data() {
    return {
      isPlay: false,
      myAuto: null
    }
  },
  
  // created() {
  //   // this.myAuto = new Audio(this.recordFile)
  // },
  // beforeDestroy() {
  //   // 停止音频播放
  //   this.myAuto.pause();
  //   // 移除加载的音频文件
  //   this.myAuto.remove();
  //   // 清空对audio元素的引用
  //   this.audio = null;
  // },
  methods: {
    autoPlay() {
      // if(this.myAuto&&this.myAuto.paused){
      //   console.log('zhixingle')
      //   this.myAuto.pause();
      // }
      // this.myAuto = null
      console.log(this.isPlay,'this.isPlay')
      if(!this.isPlay){
        console.log(this.myAuto,'this.myAuto')
        this.myAuto = new Audio(this.recordFile)
      }
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        this.myAuto.play();
        this.palyEnd();
      } else {
        this.myAuto.pause();
        this.palyEnd();
      }
    },
    palyEnd() {
      this.myAuto.addEventListener('ended', () => {
        this.isPlay = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.sound{
  float: right;
  width: 80px;
  height: 40px;
  border: 1px solid #666;
  border-radius: 20px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 32px;
    height: 32px;
  }
}
</style>

